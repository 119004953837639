import { SignedIn, SignedOut, SignInButton } from '@clerk/nextjs';
import type { ReactNode } from 'react';

import { ROUTES } from '@/hooks/use-routes';

import { Button, type ButtonProps } from './ui/button';

type ClerkSignInButtonProps = ButtonProps & {
  signedInComponent?: ReactNode;
};

export const ClerkSignInButton = (props: ClerkSignInButtonProps) => {
  const { signedInComponent, ...rest } = props;
  return (
    <>
      {signedInComponent && <SignedIn>{signedInComponent}</SignedIn>}
      <SignedOut>
        <SignInButton mode="redirect" forceRedirectUrl={ROUTES.AUTH_HOME}>
          <Button variant="outline" {...rest}>
            Sign in
          </Button>
        </SignInButton>
      </SignedOut>
    </>
  );
};
