import Link from 'next/link';

import { IconButton, type IIconButtonProps } from './icon-button';

export type ILinkButtonProps = IIconButtonProps & {
  href: string;
};

export const LinkButton = (props: ILinkButtonProps) => {
  const { href, ...rest } = props;
  return (
    <Link href={href}>
      <IconButton {...rest} />
    </Link>
  );
};
