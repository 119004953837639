'use client';

/* eslint-disable react/no-unused-prop-types */
import { SignOutButton } from '@clerk/nextjs';
import { ChevronRight, Menu } from 'lucide-react';
import Link from 'next/link';
import { useState } from 'react';

import {
  NavigationMenu,
  NavigationMenuItem,
  NavigationMenuList,
} from '@/components/ui/navigation-menu';
import {
  Sheet,
  SheetContent,
  SheetFooter,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from '@/components/ui/sheet';
import { ROUTES } from '@/hooks/use-routes';

import { ClerkSignInButton } from './clerk-sign-in-button';
import { ClerkSignUpButton } from './clerk-sign-up-button';
import { LinkButton } from './link-button';
import { ThemeToggle } from './theme-toggle';
import { Button, buttonVariants } from './ui/button';
import { Separator } from './ui/separator';

interface INavMenuItem {
  href: string;
  label: string;
}

type ISiteHeaderProps = {
  navMenuItems?: INavMenuItem[];
  showNavItemsOnHeader?: boolean;
};

export const SiteHeader = (props: ISiteHeaderProps) => {
  const { navMenuItems, showNavItemsOnHeader } = props;

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const hasNavItems = navMenuItems && navMenuItems.length > 0;

  return (
    <header className="sticky top-0 z-50 w-full border-b bg-background-light dark:border-b-slate-700 dark:bg-background">
      <NavigationMenu className="mx-auto">
        <NavigationMenuList className="container flex h-14 w-dvw px-4">
          <NavigationMenuItem className="flex font-bold">
            <Link
              rel="noreferrer noopener"
              href={ROUTES.ROOT}
              className="ml-2 flex text-lg font-bold"
            >
              {/* <LogoIcon /> FIXME: add LOGO */}
              Truffler
            </Link>
          </NavigationMenuItem>

          {/* desktop */}
          <div className="w-full px-4">
            {showNavItemsOnHeader && hasNavItems && (
              <nav className="hidden gap-2 md:flex">
                {navMenuItems.map((route: INavMenuItem, i) => (
                  <Link
                    rel="noreferrer noopener"
                    href={route.href}
                    key={i}
                    className={`text-[17px] ${buttonVariants({
                      variant: 'ghost',
                    })}`}
                  >
                    {route.label}
                  </Link>
                ))}
              </nav>
            )}
          </div>

          <div className="pr-1">
            <ThemeToggle />
          </div>

          {/* mobile */}
          <span className="flex md:hidden">
            <Sheet open={isOpen} onOpenChange={setIsOpen}>
              <SheetTrigger className="px-2">
                <span className="sr-only">Menu Icon</span>
                <Menu
                  className="flex size-5 md:hidden"
                  onClick={() => setIsOpen(true)}
                />
              </SheetTrigger>

              <SheetContent side="left">
                <SheetHeader>
                  <SheetTitle className="text-lg font-bold">
                    Truffler
                  </SheetTitle>
                </SheetHeader>

                {navMenuItems && (
                  <nav className="mt-4 flex flex-col items-start gap-2">
                    {navMenuItems.map(({ href, label }: INavMenuItem) => (
                      <Link
                        rel="noreferrer noopener"
                        key={label}
                        href={href}
                        onClick={() => setIsOpen(false)}
                        className={buttonVariants({ variant: 'ghost' })}
                      >
                        {label}
                      </Link>
                    ))}
                  </nav>
                )}
                <Separator className="my-4" />
                <SheetFooter>
                  <div className="flex w-full flex-row justify-center space-x-2">
                    <ClerkSignInButton
                      signedInComponent={
                        <SignOutButton>
                          <Button variant="outline">Sign out</Button>
                        </SignOutButton>
                      }
                      className="flex-1"
                    />
                    <ClerkSignUpButton
                      className="flex-1"
                      signedInComponent={
                        <LinkButton href={ROUTES.AUTH_HOME}>Home</LinkButton>
                      }
                    />
                  </div>
                </SheetFooter>
              </SheetContent>
            </Sheet>
          </span>

          <div className="hidden items-center gap-2 md:flex">
            <ClerkSignInButton
              signedInComponent={
                <SignOutButton>
                  <Button variant="outline">Sign out</Button>
                </SignOutButton>
              }
            />
            <ClerkSignUpButton
              size="sm"
              Icon={ChevronRight}
              signedInComponent={
                <LinkButton href={ROUTES.AUTH_HOME}>Home</LinkButton>
              }
            />
          </div>
        </NavigationMenuList>
      </NavigationMenu>
    </header>
  );
};
