import { type LucideIcon, type LucideProps } from 'lucide-react';
import type { PropsWithChildren } from 'react';

import { cn } from '@/lib/utils';

import { Spinner } from './spinner';
import { Button, type ButtonProps } from './ui/button';

export type IIconButtonProps = PropsWithChildren &
  ButtonProps & {
    hideIcon?: boolean;
    loading?: boolean;
    selected?: boolean;
    innerContainerClassName?: string;
    iconLocation?: 'start' | 'end';
    iconClassName?: string;
    Icon?: LucideIcon | React.JSX.ElementType;
    iconProps?: LucideProps;
  };

export const IconButton = (props: IIconButtonProps) => {
  const {
    Icon,
    iconClassName,
    innerContainerClassName,
    iconLocation,
    iconProps,
    className,
    hideIcon,
    loading,
    children,
    ...rest
  } = props;
  return (
    <Button
      variant="secondary"
      className={cn(`${props.selected ? 'bg-accent' : ''}`, className)}
      {...rest}
    >
      <div
        className={cn('flex flex-row items-center', innerContainerClassName)}
      >
        {loading && <Spinner className="mr-2 size-4" />}
        {iconLocation === 'start' && !hideIcon && Icon && (
          <div className={cn(children && 'mr-2')}>
            <Icon className={cn('size-4', iconClassName)} {...iconProps} />
          </div>
        )}
        {children}
        {iconLocation !== 'start' && !hideIcon && Icon && (
          <div className={cn(children && 'ml-2')}>
            <Icon className={cn('size-4', iconClassName)} {...iconProps} />
          </div>
        )}
      </div>
    </Button>
  );
};
