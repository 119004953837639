import { SignedIn, SignedOut, SignUpButton } from '@clerk/nextjs';
import type { ReactNode } from 'react';

import { ROUTES } from '@/hooks/use-routes';

import { IconButton, type IIconButtonProps } from './icon-button';

type ClerkSignUpButtonProps = IIconButtonProps & {
  buttenText?: string;
  signedInComponent?: ReactNode;
};

export const ClerkSignUpButton = (props: ClerkSignUpButtonProps) => {
  const { signedInComponent, buttenText, ...rest } = props;
  return (
    <>
      {signedInComponent && <SignedIn>{signedInComponent}</SignedIn>}
      <SignedOut>
        <SignUpButton mode="redirect" forceRedirectUrl={ROUTES.AUTH_HOME}>
          <IconButton variant="secondary" {...rest}>
            {buttenText ?? 'Get started'}
          </IconButton>
        </SignUpButton>
      </SignedOut>
    </>
  );
};
